import React, {FC} from "react";
import getTemplateText from "../../services/TextService";

import Link from "../UI/Link";
import {urlTo} from "../../services/RouteService/RouteService";
import {ROUTE} from "../../services/RouteService/enums";
import "./BonusPreview.styl";

const BonusPreview: FC<{currentBonus: string | number, withDescription?: boolean}> = ({currentBonus = 0, withDescription = true}) => {
	return (
		<div className="bonus-preview">
			{withDescription ?
				<h3 className="bonus-preview__header">Кешбек бонуси</h3>
				: <h1 className="bonus-preview__header heading3">Кешбек бонуси</h1>}
			<Link to={urlTo(ROUTE.BONUS)}>
				<div className="bonus-preview__content">
					<div className="bonus-preview__data">
						<h4 className="data__title"> Доступно </h4>
						<div className="data__value">
							<span className="bonus-amount">{(+currentBonus).toFixed(2)}<img className="bonus-currency" src='/CashbackBonus.svg' alt="Кешбек бонусів" /></span>

						</div>
					</div>
					{withDescription &&
						<div className="bonus-preview__text">
							<p className="text__title"> Чудова новина! </p>
							<p className="text__description">
								Пропонуємо оновлений ділимий кешбек бонус від «Фора club»: використовуйте потрібну суму бонусу в кожній покупці
							</p>
						</div>
					}
				</div>
			</Link>
		</div>
	)
};

export default BonusPreview;