import React, {Component} from 'react';
import cn from "classnames";
import LazyLoad from "react-lazyload";
import {Link} from "react-router-dom";
import Toggle from "react-toggle"

import {ProposalItemType} from "../../../graphql/types";
import {
	handleSwitcherToggleType,
	ProposalItemExtendType,
	ProposalItemPropsTypeExtended,
	ProposalItemStateType
} from "./types";

import {getImagePlaceholder, getProposalStatusText} from "../../../services/ProposalService";
import {getNearestStore} from "../../../services/AppServices";
import {FormattedDate, FormattedHTMLMessage, renderNode} from "../../../services/TextService"
import {isFunction, isValidDate} from "../../../utils";
import {SHOP_HOST} from '@services/EnvServices';
import ProposalLike from '../ProposalLike/ProposalLike';

import Icon from "../../UI/Icon/Icon";
import {ICONS} from "../../UI/Icon/enums";
import Button from "../../UI/Button/Button";

import "./ProposalItem.styl";

/***
 * Preset image height
 * @returns {string}
 */
function lazyLoadHeight() : string {
	const width = window["width"];
	let height = "300px";
	if (width <= 500) {
		height = "240px";
	} else if (width <= 768) {
		height = "190px"
	}
	return height;
}


class ProposalItem extends Component<ProposalItemType & ProposalItemPropsTypeExtended, ProposalItemStateType> implements ProposalItemExtendType{
	constructor(props) {
		super(props);
		this.state = {
			switchOn: props.manual ? !props.position : null,
			imageLink: props.listImages && props.listImages.length > 5 ? props.listImages[5] : getImagePlaceholder(),
			bubbles: []
		};
	}
	proposalItem = null;
	/***
	 * Mark coupon
	 */
	markCoupon = () => {
		const {id, useType, markCoupon} = this.props;
		if (typeof markCoupon === "function") {
			markCoupon({id, useType});
		}
	};


	/***
	 * Detect support of the store
	 * @returns {boolean}
	 */
	isSupportedStore = () => {
		const {addresses = []} = this.props;
		const activeNearestStore = (getNearestStore() || [])[0];

		return !!addresses.find(item => item.store && item.store.id === activeNearestStore);
	};

	/***
	 * Render store label
	 * @returns {*}
	 */
	renderStoreLabel = () => {
		const {activeStoreData} = this.props;
		const isSupported = this.isSupportedStore();
		return (
			<div className="proposal-image-item__not-supported">
				<Icon icon={ICONS.marker_2} raw/>
				<p className="snippet">
					Ця пропозиція {!isSupported && "не"} діє в&nbsp;
					<FormattedHTMLMessage id="store.list_item.title"
																values={{street: (activeStoreData || {title: undefined}).title}}/>
				</p>
			</div>
		)
	};

	switcherOnChange =(id: string, manual: boolean | undefined, switchOn: boolean | null, handleSwitcherToggle: handleSwitcherToggleType | undefined) => () => {
		if (manual) {
			this.setState({switchOn: !switchOn})
		}
		if (typeof handleSwitcherToggle === "function") {
			handleSwitcherToggle(id)
		}
	};

	refHandle = (node: any) => {
		this.proposalItem = node;
	};

	render() {
		const {id, addresses} = this.props;
		if (!id) return null;
		const activeNearestStore = (getNearestStore() || [])[0];
		const {
			startedAt, endAt, className, position, handleSwitcherToggle, markCoupon, marked, showMark,
			isChangeable, archived, signText, rewardValue, unitText, couponDescription, limitDescription,
			promoDescription, manual, useType, isCouponControl, multipleUsingEnable, isLike
		} = this.props;
		const {switchOn, imageLink} = this.state;
		const isElectronic = useType === "ELECTRONIC" ? isCouponControl : false;
		const canMarkCoupon = isFunction(markCoupon) && isChangeable && !showMark;

		const proposalUrl = `${SHOP_HOST}/personal-proposals/${id}`

		const startedAtDate = new Date(startedAt),
			endAtDate = new Date(endAt);
		let inactiveProposal = position || (manual && !switchOn) ? "inactive-proposal" : "";
		if (manual) {
			inactiveProposal = (manual && !switchOn) ? "inactive-proposal" : "";
		}
		return (
			<div className={cn("proposal-image-item", {
				expired: archived,
				"show-mark": showMark,
				marked,
			}, className, inactiveProposal)} ref={this.refHandle}>
				<div className="proposal-image-item__body">
					<LazyLoad offset={100} height={lazyLoadHeight()}>
						<div className="proposal-image-item__item-image">
							<img src={imageLink} alt={couponDescription}/>
						</div>
					</LazyLoad>
					<div className="proposal-image-item__footer">
						<div className="proposal-image-item__footer-first-row">
							<div className="proposal-image-item__reward-holder">
								<div className={`proposal-image-item__reward ${unitText && unitText.length === 1 ? "proposal-image-item__percent" : ""}`}>
									<span className={"proposal-image-item__reward-sign"}>
										{signText}
									</span>
									{rewardValue}{unitText && unitText.length === 1 ? unitText : ""}
								</div>
								{unitText && unitText.length > 1 ?<div className="proposal-image-item__unit">
									{renderNode(unitText)}{unitText==='кешбек бонусів'?<img className="bonus-currency" src='/CashbackBonus.svg' alt="Кешбек бонусів" /> : ''}
								</div> : null}
							</div>
							<div className="proposal-image-item__description-holder">
								<div className="proposal-image-item__coupon-description heading3">
									<Link className="proposal-image-item__link-item" to={{pathname: proposalUrl}} target="_blank">
										{renderNode(`${couponDescription} ${!!promoDescription ? promoDescription : ""}`)}
									</Link>
								</div>
								<hr/>
								<div className="proposal-image-item__limit-description">
									{!!promoDescription && renderNode(promoDescription)} {renderNode(limitDescription)}
								</div>
							</div>
						</div>
						<div className="proposal-image-item__footer-second-row">
								{archived ? (
									<div className="proposal-image-item__period">
										{getProposalStatusText(this.props)}
									</div>
								) : !!isValidDate(startedAtDate) && !!isValidDate(endAtDate) && (
									<div className="proposal-image-item__period">
										<ProposalLike {...{isLike: isLike, itemId: id}} />
										<div>
											{multipleUsingEnable ? "багаторазова" : "одноразова"}
											<br/>
											<FormattedHTMLMessage id="proposal.period.title"/>
											<br/>
											<FormattedDate value={startedAtDate}/>
											<FormattedHTMLMessage id="proposal.period.divider"/>
											<FormattedDate value={endAtDate}/>
										</div>
										<div className="proposal-image-item__switcher-container">
											{isElectronic && typeof handleSwitcherToggle !== undefined && !archived &&
											<div className="proposal-image-item__switcher-wrapper">
												<div className="proposal-image-item__switcher-title">
												</div>
												{(!!activeNearestStore && !!addresses && !!addresses.length) ? this.renderStoreLabel() :
												<div className="proposal-image-item__switcher">
													<Toggle
														checked={manual ? switchOn : !position}
														icons={false}
														onChange={this.switcherOnChange(id, manual, switchOn, handleSwitcherToggle)}
														className="proposal-image-item__toggle"
													/>
												</div>}
											</div>}
										</div>
									</div>
								)}
						</div>
					</div>
				</div>

				<div className="product-list__item-promotion-label">
					{!isElectronic && canMarkCoupon && (
						<Button
							onClick={this.markCoupon}
							className="proposal-image-item__change-type-btn"
						>
							<Icon icon={ICONS.transfer}/>
						</Button>)
					}
				</div>

				{showMark && (
					<div className="proposal-image-item__change-type-holder">
						<Button
							className={cn("proposal-image-item__change-type-radio", {marked})}
							onClick={isChangeable ? this.markCoupon : null}
							disabled={!isChangeable}
						/>
					</div>
				)}
			</div>
		)
	}
}

export default ProposalItem;