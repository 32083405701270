import React, {Component} from 'react';
import {graphql} from "react-apollo";
import {compose} from "recompose";

import {MoneyBoxPageProps, MoneyBoxPageState} from "./types";

import Query from "../../graphql/Query";
import {MONEYBOX_STATUS} from "../../constants";
import {ICONS} from "../../components/UI/Icon/enums";
import {ROUTE} from "../../services/RouteService/enums";
import {setDocumentTitle} from "../../services/TitleService";
import getTemplateText, {renderNode} from "../../services/TextService";

import Icon from "../../components/UI/Icon/Icon";
import Loader from "../../components/UI/Loader/Loader";
import Moneybox from "../../components/Moneybox/Moneybox";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import IconTextList from "../../components/IconTextList/IconTextList";

import "./MoneyBoxPage.styl";


const INTRO_TEXT = `
	Дрібні гроші завжди є!</br>
	Відкладайте дрібну решту менше 1 грн на Ваш «Копійковий рахунок».</br>
	Сума коштів на Копійковому рахунку входить у кешбек бонуси.</br>
	Гроші з «Копійкового рахунку» можна використовувати в будь-якому чеку.</br>
`;
const INTRO_TEXT_NOTE = `*послуга Копійковий рахунок не може зменшити суму до сплати при наявності в чеці тютюнових виробів.`;

const MoneyBoxPage = compose<MoneyBoxPageProps, {}>(
	graphql(Query.moneyBox.query, {name: "moneyBoxData"}),
)(class MoneyBoxPage extends Component<MoneyBoxPageProps, MoneyBoxPageState> {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpened: false,
			mutationLoading: false,
			error: null
		}
	}


	render() {
		const {moneyBoxData: {loading, moneyBox}} = this.props;
		const {status="", title=""} = moneyBox || {};
		const isActivated = (status === MONEYBOX_STATUS.ACTIVATED || status === MONEYBOX_STATUS.NOT_ACTIVATED);

		setDocumentTitle(title);

		if (loading) {
			return <Loader/>
		}

		return (
			<div className="moneybox-page">
				<BreadCrumbs labels={{
					[ROUTE.PERSONAL_PAGE]: "Персональна сторінка",
					[ROUTE.MONEYBOX]: getTemplateText("aboutMoneybox.title")}
				}/>

				<div className="moneybox-intro">
					<h1 className="moneybox-intro__heading">КОПІЙКОВИЙ РАХУНОК</h1>
					<div className="moneybox-intro__text">{renderNode(INTRO_TEXT)}</div>
					<div className="moneybox-intro__text-details">{renderNode(INTRO_TEXT_NOTE)}</div>
				</div>
				{
					//@ts-ignore
					<Moneybox isMoneyboxPage/>
				}
				{!isActivated &&	<div className="moneybox-conditions">
					<div className="moneybox-conditions__head">
						<h3 className="moneybox-conditions__heading">{getTemplateText("moneyBox.conditions.heading")}</h3>
						<a className="moneybox-conditions__link" href={getTemplateText("link.moneyBox")}
							 rel="noopener noreferrer" target="_blank">
							Офіційні правила<Icon icon={ICONS.arrowRight}/>
						</a>
					</div>
					<IconTextList
						className="moneybox-conditions__list"
						items={[
							{
								image: require("../../../img/account/moneybox/icon-moneybox-manage.svg"),
								text: getTemplateText("moneyBox.conditions.rule_1")
							},
							{
								image: require("../../../img/account/moneybox/icon-moneybox-gather.svg"),
								text: getTemplateText("moneyBox.conditions.rule_2")
							},
							{
								image: require("../../../img/account/moneybox/icon-moneybox-time.svg"),
								text: getTemplateText("moneyBox.conditions.rule_3")
							},
						]}
					/>
				</div>}
			</div>
		)
	}
});
export default MoneyBoxPage;
